import en from '../translations/en.json';
import pl from '../translations/pl.json';
export default class WcmTranslator {
    _attributeName = 'data-wcm-translator'
    _selector = `[${this._attributeName}]`;
    _tanslationFilesFolderPath = '../translations';
    _clientLang = 'en';
    _htmlElements = [];
    _pl = 'pl';
    _en = 'en';
    _currentTranslation = null;

    constructor(transFilesFolderPath = null) {
        this._clientLang = this.getLanguage();
        if (transFilesFolderPath) {
            this._tanslationFilesFolderPath = transFilesFolderPath;
        }
        this._htmlElements = document.querySelectorAll(this._selector);
        this.load();
    }

    load(lang = null) {
        if (lang) {
            this._clientLang = lang;
        }

        if (this._clientLang != this._pl) {
            this._clientLang = this._en;
        }

        switch (this._clientLang) {
            case 'pl':
                this._currentTranslation = pl;
                break;
            case 'en':
                this._currentTranslation = en;
                break;
        }

        this.translate();


    }

    getLanguage() {
        var lang = navigator.languages ? navigator.languages[0] : navigator.language;
        return lang.substr(0, 2);
    }

    translate() {
        this._htmlElements.forEach(el => {
            var translatedValue = this.fetchFromObject(this._currentTranslation, `${el.getAttribute(this._attributeName)}`);

            if ((el.tagName === "INPUT" || el.tagName === "TEXTAREA")
                && (el.placeholder && el.placeholder.length > 0)) {
                el.placeholder = translatedValue;
                return;
            }

            el.innerHTML = translatedValue;
        })
    }

    fetchFromObject(obj, prop) {
        if (typeof obj === 'undefined') {
            return false;
        }

        var _index = prop.indexOf('.')
        if (_index > -1) {
            return this.fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
        }

        return obj[prop];
    }
}