import './css/style.css';
import 'jquery/dist/jquery.slim';
import 'popper.js/dist/popper';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos/dist/aos';
import 'aos/dist/aos.css';
import '@splidejs/splide/dist/js/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
// import SmoothScroll from 'smooth-scroll/dist/smooth-scroll';
import 'material-design-lite/dist/material.min.css'
import WcmTranslator from './js/translator';
import './js/nav';
// import sendEmail from './js/mailSender';

document.addEventListener('DOMContentLoaded', function () {
    new Splide('#splide', {
        type: 'loop',
        autoplay: false,
        cover: true,
    }).mount();
    new Splide('#splide-offer', {
        type: 'loop',
        autoplay: false,
        cover: true
    }).mount();
    new Splide('#splide-tech', {
        type: 'loop',
        autoplay: false,
        cover: true
    }).mount();

    // var scroll = new SmoothScroll('a[href*="#"]', {
    //     offset: 100,
    //     speed: 2
    // });
});

// const form = document.getElementById("contact-form");
// form.addEventListener("submit", event => {
//     event.preventDefault();
//     sendEmail(form);
// });
var translation;
window.onload = (() => {
    translation= new WcmTranslator();
    toggleTechnologies("init");
    AOS.init();
    for (let el of document.getElementById("navMobile").children) {
        el.addEventListener('click',() => {
            document.getElementById("toogler").click();
        })
    }
})

var techIsOpened = false;
// document.querySelector('.navbar-nav>li>a>span>a').addEventListener('click', function(){
//     console.log('test');
//     document.querySelector('.navbar-collapse').collapse('hide');
// });
var techItems = document.getElementById("tech_items").children;
var techButtonArrow = document.getElementById("arrow-tech-button");
var techButtonText = document.getElementById("text-tech-button");
var techButtonTextLess = document.getElementById("text-less-tech-button");

function changeLang() {
    translation.load(translation._clientLang != 'pl' ? 'pl' : 'en');
}

function toggleTechnologies(init = null) {
    if (!init) {
        techIsOpened = !techIsOpened;
        if (techIsOpened) {
            techButtonArrow.classList.add("rotate-180");
            techButtonArrow.classList.remove("rotate-0");
            techButtonText.classList.add("d-none");
            techButtonTextLess.classList.remove("d-none");
            cardsShow(8, 8, techItems.length - 2, techItems);
        } else {
            techButtonArrow.classList.remove("rotate-180");
            techButtonArrow.classList.add("rotate-0");
            techButtonText.classList.remove("d-none");
            techButtonTextLess.classList.add("d-none");
            cardsHide(techItems.length - 2, 8, techItems.length - 2, techItems);
        }
    }
}

function cardsShow(index, startIndex, lastIndex, items) {
    setTimeout(() => {
        items[index].classList.remove("mx-h-0");
        items[index].classList.add("mx-h-100");
        items[index].classList.add("pb-1");
        if (index != lastIndex) {
            cardsShow(++index, startIndex, lastIndex, items);
        }
    }, (index * 80) * 0.75 - startIndex * 100);
}
function cardsHide(index, startIndex, lastIndex, items) {
    setTimeout(() => {
        items[index].classList.remove("mx-h-100");
        items[index].classList.add("mx-h-0");
        if (index != startIndex) {
            cardsHide(--index, startIndex, lastIndex, items);
        }
    }, (index * 80) * 0.75 - startIndex * 100);
}

window.cardsShow = cardsShow;
window.cardsHide = cardsHide;
window.toggleTechnologies = toggleTechnologies;
window.changeLang = changeLang;

// Needed for Hot Module Replacement
if (typeof (module.hot) !== 'undefined') {
    module.hot.accept() // eslint-disable-line no-undef  
}
