window.onscroll = function () {
    navScroll();
    document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
};
var navbar = document.getElementById("nav");
var menu = document.getElementById("menu");
var logo = document.getElementById("logo");
var timeout = null;

const navScroll = () => {
    if (window.pageYOffset > 0) {
        logo.classList.add('scale-down-nav');
        logo.classList.remove('scale-up-nav');
        menu.classList.add('menu-padding-incress');
        menu.classList.remove('menu-padding-decress');
        navbar.style.backgroundColor = 'white';
        !timeout ? timeout = setTimeout(() => {
            if (window.pageYOffset > 0) {
                navbar.classList.add('mdl-shadow--3dp');
                timeout = null;
            }
        }, 450) : timeout;
    } else {
        logo.classList.remove('scale-down-nav');
        logo.classList.add('scale-up-nav');
        menu.classList.remove('menu-padding-incress');
        menu.classList.add('menu-padding-decress');
        navbar.classList.remove('mdl-shadow--3dp');
        navbar.style.backgroundColor = 'transparent';
        timeout = null;
    }
};
export default navScroll
